import { MumfyAuth } from "./MumfyAuth";
import apiUrl from "../config/btpApi";
import errorHandling from "../components/errorhandling/ErrorHandle";
import { customDate } from "../config/customDate";

const Campaigns = {
  getCampaigns: async () => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.campaignUrl + "get-all?limit=100", "get");
      return response;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  getCampaignById: async (id) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.campaignUrl + "get-all/?campaign_id=" + id,
        "get",
        {}
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  deleteCampaign: async (id) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.campaignUrl + id, "delete", {});
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  editCampaign: async (id, data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.campaignUrl + id, "patch", {}, data);
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  createCampaign: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.campaignUrl + "create", "post", {}, data);
      return response.data;
    } catch (err) {
      return errorHandling.handleErrors(err?.response?.data?.msg || err);
    }
  },

  campaignReview: async (id) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.campaignUrl + `submit-review/${id}`,
        "patch",
        "",
        {}
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  manageCampaign: async (id, action) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.campaignUrl + `manage/${id}?action=${action}`,
        "patch",
        "",
        {}
      );
      return response.data;
    } catch (err) {
      return errorHandling.handleErrors(err?.response?.data?.msg || err);
    }
  },

  campaignReports: async (id, date) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.campaignReportsUrl +
          `?startDate=${date?.startDate}&endDate=${date?.endDate}&component=ad&id=${id}`,
        "post",
        "",
        {}
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },

  getAdminGroup: async () => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.getAdminGroupUrl, "get");
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  },
  getAdsReport: async (id, filteredData) => {
    try {
      let url = apiUrl.adsReportApi + "/" + id;
      //"/665067bd7c7d60ad088f292d";
      //+ id;
      if (filteredData && filteredData?.startDate && filteredData?.endDate) {
        url +=
          "?startDate=" +
          customDate.getUtcDate(filteredData?.startDate) +
          "&endDate=" +
          customDate.getUtcDate(filteredData?.endDate);
      }
      const response = await MumfyAuth.fetchData(url, "get");
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err);
      throw err;
    }
  }
};

export default Campaigns;
