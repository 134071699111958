const coreApi = process.env.REACT_APP_CORE_API;
const adsApi = process.env.REACT_APP_CORE_API + "api/ads/v1/";
const reportsV4Api = process.env.REACT_APP_MOM_API + "api/reports/v4/";
const brandlabsApi = process.env.REACT_APP_BRANDLABS_API;
const authApi = process.env.REACT_APP_CORE_API + "api/";
const gstApi = process.env.REACT_APP_GST_API + "free/gstin/";
const groupApi = process.env.REACT_APP_CORE_API + "api/groups/v1/";

const apiUrl = {
  loginUrl: coreApi + "api/users/v1/login",
  registerUrl: coreApi + "api/users/v1/register",
  profileUrl: coreApi + "api/users/v1/",
  otherUsersProfile: coreApi + "api/users/v1/",
  updateUserUrl: coreApi + "api/users/v1/",
  updateBusinessInfo: brandlabsApi + "api/onboarding/update",
  emailUrl: process.env.REACT_APP_EMAIL_API + "bulk-mail",
  sendEmailUrl: process.env.REACT_APP_EMAIL_API + "api/v1/email/send-mail",
  adsUrl: adsApi + "ad",
  getAdsUrl: adsApi + "ads",
  sponsoredPost: process.env.REACT_APP_BRANDLABS_API + "admin/api/sponsored-posts",
  sponsoredPostById: process.env.REACT_APP_BRANDLABS_API + "admin/api/sponsored-post",
  ticketById: process.env.REACT_APP_BRANDLABS_API + "admin/api/ticket/",
  fileDetailsById: process.env.REACT_APP_BRANDLABS_API + "admin/api/file-details/",
  putSponsoredPostUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/sponsored-post",
  ticketUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/ticket",
  sendMessageUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/send-message",
  transactionByIdUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/transaction?productId=",
  applyCouponUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/validate-coupon",
  paymentUpdateUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/payment-status",
  emaiAddsPostUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/email-adds",
  emaiAdsByIdUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/email-add",
  couponUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/coupons/",
  createCouponUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/create-coupon/",
  deleteCouponUrl: process.env.REACT_APP_BRANDLABS_API + "admin/api/coupon/",
  mediaUrl: process.env.REACT_APP_MEDIA_API + "api/media/",
  getAllBrandsUrl: brandlabsApi + "admin/api/onboarding/",
  getSingleBrandUrl: brandlabsApi + "api/onboarding/",
  getAllCreatives: brandlabsApi + "api/creative/",
  fileDetailsApi: brandlabsApi + "api/file-details/",
  sponsoredPostUrl: brandlabsApi + "api/sponsored-post",
  onBoardingUrl: brandlabsApi + "api/onboarding/",
  phoneOtpUrl: authApi + "users/",
  campaignUrl: brandlabsApi + "api/campaign/",
  paypalOrderApi: brandlabsApi + "api/order-paypal",
  paypalCaptureApi: brandlabsApi + "api/capture-paypal",
  razorPayOrderApi: brandlabsApi + "api/order/",
  razorPayCaptureApi: brandlabsApi + "api/capture/",
  leadGenApi: brandlabsApi + "api/leadgen/",
  getLedgerUrl: brandlabsApi + "api/ledger/",
  getTransactionsUrl: brandlabsApi + "api/transaction/",
  getLeadsUrl: brandlabsApi + "api/leadgen/get-all/",
  internalLogin: process.env.REACT_APP_CORE_API + "api/internalUsers/v1/token",
  getGstDataUrl: gstApi,
  transactionApi: brandlabsApi + "transaction?",
  campaignReportsUrl: reportsV4Api,
  requestPhoneOtp: coreApi + "api/users/v1/request-phone-otp",
  verifyPhoneOtp: coreApi + "api/users/v1/verify-phone-otp",
  getAdminGroupUrl: groupApi + "groups?isAdmin=true",
  adsReportApi: brandlabsApi + "api/campaign/report"
};

export default apiUrl;
