import { message } from "antd";
import "react-toastify/dist/ReactToastify.css";

const errorHandling = {
  async handleErrors(response) {
    console.log("response", response);
    if (response?.status === "401" || response?.status === "403") {
      await message.error({ content: response?.message, key: "error" });
      window.location = "/";
      return;
    }
    // else if (data !== "IN" || "") {
    //   window.location = "/abroad";
    // } else if (data == "IN") {
    //   window.location = "/on-boarding";
    // }
    else if (
      response === "User is not not onboarded" ||
      response?.errorMsg === "User is not not onboarded"
    ) {
      // window.location = "/on-boarding";
    } else if (response?.response?.data?.isOnboarded === false) {
      //await message.error(response.msg, { toastId: "error" });
      window.location = "/onboard";
    } else if (response?.message) {
      console.log("test2");
      await message.error({ content: response?.message, key: "error" });
      return;
    } else if (response?.msg) {
      console.log("test1");
      await message.error({ content: response?.msg, key: "error" });
      return;
    } else {
      console.log("test");
      await message.error({ content: response, key: "error" });
      return;
    }
    return true;
  }
};

export default errorHandling;
