import { CloseCircleOutlined } from "@ant-design/icons";
import { Button, Input, Modal, Form, Alert, Row, Col } from "antd";
import React, { useState, useContext, useEffect } from "react";
import Profile from "../../services/Profile";
import Cookies from "js-cookie";
import { useHistory } from "react-router-dom";
import CountryPhoneInput, { ConfigProvider } from "antd-country-phone-input";
import en from "../../config/countries.json";
import "antd-country-phone-input/dist/index.css";
import { redirectToPage } from "../Redirect";
import { ProfileContext } from "../../context/ProfileContext";
import propTypes from "prop-types";
import { Statistic } from "antd";
import PhoneValidation from "../../services/PhoneValidation";

const { Countdown } = Statistic;
const Login = (props) => {
  Login.propTypes = {
    action: propTypes.any,
    open: propTypes.bool,
    handleClose: propTypes.func
  };
  const { login, profile, onboardData } = useContext(ProfileContext);
  const navigate = useHistory();

  const [form] = Form.useForm();
  const [loginDetails, setLoginDetails] = useState({
    otp: "",
    fullName: "",
    email: ""
  });
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [phoneData, setPhoneData] = useState({ short: "IN" });
  const [action, setAction] = useState("Register");
  const [message, setMessage] = useState("");
  const [deadline, setDeadline] = useState(Date.now() + 10 * 6000);
  const [isDisabledResendOtp, setIsDisabledResendOtp] = useState(true);

  useEffect(() => {
    setLoginDetails({
      otp: "",
      fullName: "",
      email: ""
    });
    setPhoneData({ short: "IN", phone: "" });
    setAction(props.action);
    form.resetFields();
  }, [props.action, props.open, form]);

  useEffect(() => {
    if (isOtpSent) {
      setDeadline(Date.now() + 10 * 6000);
    }
  }, [isOtpSent]);

  const requestPhoneOtp = async () => {
    const phoneNumber = phoneData?.phone?.replace(/\D/g, "");
    const data = {
      phone: "+" + phoneData?.code + phoneNumber
    };
    let response = {};
    if (action === "Register") {
      data.full_name = loginDetails.fullName;
      data.email = loginDetails.email;
      response = await Profile.register(data);
    } else {
      response = await Profile.requestPhoneOtp(data);
    }
    if (response?.data?.action === "register") {
      setAction("Register");
    }
    if (response?.data?.action === "login") {
      setMessage(
        "This number is already been registered with us as customer. We have sent an OTP to this number to register as an advertiser. Please enter the OTP to login!!"
      );
      setAction("Login");
    }
    if (response?.data?.status === true || response?.data?.status === "success") {
      setIsOtpSent(true);
    }
  };

  const verifyPhoneOtp = async () => {
    loginDetails.phone = "+" + phoneData?.code + phoneData?.phone;
    const response = await Profile.verifyPhoneOtp(loginDetails);
    if (response?.status === 200) {
      Cookies.set("accessToken", response.data?.token);
      await login(response.data?.token);
      try {
        await redirectToPage(navigate, profile, onboardData);
      } catch (error) {
        message.error("Failed to load profile. Please try again");
      }
    }
  };
  const hadleSubmit = () => {
    form
      .validateFields()
      .then(async () => {
        if (isOtpSent === true) {
          await verifyPhoneOtp();
        } else {
          await requestPhoneOtp();
        }
      })
      .catch((errorInfo) => {
        console.log("Validation failed:", errorInfo);
      });
  };
  const handleChange = (event) => {
    setLoginDetails((prevFormData) => ({
      ...prevFormData,
      [event.target.name]: event.target.value
    }));
  };

  const validatePhoneNumber = (_, value) => {
    return PhoneValidation.validatePhoneNumber(value);
  };
  const onFinish = () => {
    setIsDisabledResendOtp(false);
  };
  const resendOtp = async () => {
    await requestPhoneOtp();
    setDeadline(Date.now() + 10 * 6000);
  };

  const validateName = (_, value) => {
    if (!value) {
      return Promise.reject(new Error("Please enter your Name"));
    }
    if (value.length < 3) {
      return Promise.reject(new Error("Name should have at least 3 characters"));
    }
    if (/^\d/.test(value)) {
      return Promise.reject(new Error("Name should not start with a number"));
    }
    return Promise.resolve();
  };
  return (
    <Modal
      centered
      open={props.open}
      footer={null}
      onCancel={props.handleClose}
      title={action}
      closeIcon={<CloseCircleOutlined onClick={props.handleClose} />}
      maskClosable={false}
    >
      <Form name="login" className="" layout="vertical" form={form} onFinish={hadleSubmit}>
        {action === "Register" && isOtpSent === false ? (
          <>
            <Form.Item
              name="fullName"
              label="Enter Name"
              className="mb-4"
              rules={[{ validator: validateName }]}
              // rules={[{ required: true, message: "Please enter Name" }]}
            >
              <Input
                placeholder="Full Name"
                name="fullName"
                className="budget"
                onChange={handleChange}
                value={loginDetails.fullName}
              />
            </Form.Item>
            <Form.Item
              name="email"
              label="Enter Email"
              className="mb-4"
              rules={[
                { required: true, message: "Please enter your Email" },
                {
                  type: "email",
                  message: "Please enter valid email"
                }
              ]}
            >
              <Input
                placeholder="Email"
                name="email"
                className="budget"
                onChange={handleChange}
                value={loginDetails.email}
              />
            </Form.Item>
          </>
        ) : null}

        {isOtpSent === true ? (
          <>
            {message ? (
              <>
                <Alert type="info" message={message}></Alert> <br />
              </>
            ) : null}
            <Form.Item name="otp" label="Enter OTP" className="mb-4" rules={[{ required: true }]}>
              <Input
                name="otp"
                placeholder="OTP"
                className="budget"
                onChange={handleChange}
                value={loginDetails.otp}
              />
            </Form.Item>
            <div style={{ display: "flex", alignItems: "center", fontSize: "14px" }}>
              <span style={{ marginRight: "5px" }}>OTP expires in</span>
              <Countdown value={deadline} onFinish={onFinish} valueStyle={{ fontSize: "14px" }} />
            </div>
          </>
        ) : (
          <ConfigProvider
            theme={{
              token: {
                fontSize: 18,
                fontFamily: "amnes"
              }
            }}
            locale={en}
          >
            <Form.Item
              name="phone"
              label="Enter Phone Number"
              className="mb-6 budget"
              rules={[{ validator: validatePhoneNumber }]}
              initialValue={{ short: "IN" }}
            >
              <CountryPhoneInput
                name="phone"
                inline
                placeholder="Phone"
                value={phoneData}
                onChange={(v) => {
                  setPhoneData(v);
                }}
                className="budget"
              />
            </Form.Item>
          </ConfigProvider>
        )}
        <Form.Item>
          <Row justify="end" gutter={16}>
            {isOtpSent === true ? (
              <Col>
                <Button
                  disabled={isDisabledResendOtp}
                  type="primary"
                  onClick={resendOtp}
                  style={{ float: "right" }}
                >
                  Resend OTP
                </Button>{" "}
              </Col>
            ) : null}{" "}
            <Col>
              <Button type="primary" htmlType="submit">
                Submit
              </Button>
            </Col>
          </Row>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default Login;
