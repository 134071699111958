import React, { createContext, useEffect, useState } from "react";
import Profile from "../services/Profile";
import Cookies from "js-cookie";
import propTypes from "prop-types";
import Onboarding from "../services/Onboarding";
import Campaigns from "../services/Campaigns";

export const ProfileContext = createContext();

export const ProfileProvider = ({ children }) => {
  ProfileProvider.propTypes = {
    children: propTypes.any
  };

  const [profile, setProfile] = useState();
  const [wallet, setWallet] = useState(0);
  const [businessInfo, setBusinessInfo] = useState({});
  const [onboardData, setOnboardData] = useState({});
  const [campaignData, setCampaignData] = useState({
    objective: "",
    start_date: "",
    ad_type: "image",
    ad_creative: [
      {
        media_url: "",
        headline: "",
        destinationUrl: "",
        callToAction: ""
      }
    ]
  });

  const [creativeData, setCreativeData] = useState([
    {}
    // {
    //   media_url: "",
    //   headline: "",
    //   destinationUrl: "",
    //   callToAction: "",
    // },
  ]);

  const [creative, setCreative] = useState({
    media_url: "",
    headline: "",
    destinationUrl: "",
    callToAction: ""
  });

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [adType, setAdType] = useState("image");

  let token = "";
  const login = async (token) => {
    setIsLoggedIn(true);
    setAccessToken(token);
  };
  const getProfile = async () => {
    const data = await Profile.getUserDetails();
    if (data) {
      setProfile(data.data);
    } else {
      setProfile({});
    }
  };

  const getBusinessInfo = async () => {
    const data = await Onboarding.getBusinessDetails();
    if (data?.data?.data?.onboard_data?.length > 0) {
      setBusinessInfo(data?.data?.data?.onboard_data[0]);
      setWallet(data?.data?.data?.onboard_data[0]?.totalAmount);
    }
  };
  useEffect(() => {
    const currentUrl = window.location.pathname;
    if (currentUrl === "/") {
      token = "";
    } else {
      token = Cookies.get("accessToken");
    }
    if (token) {
      getProfile();
      getBusinessInfo();
    }
    if (isLoggedIn && accessToken) {
      getProfile();
      getBusinessInfo();
    }
  }, [isLoggedIn, accessToken, token]);

  const setBusinessDetails = (field, value) => {
    setOnboardData((onboardData) => ({ ...onboardData, [field]: value }));
  };

  const setCampaignInfo = (field, value) => {
    setCampaignData((campaignData) => {
      const newCampaignData = { ...campaignData, [field]: value };

      return newCampaignData;
    });
  };
  const setGroupCreative = (field, value) => {
    console.log("field", field);
    setCreativeData((creativeData) => [{ ...creativeData[0], [field]: value }]);
  };

  const setCreativeInfo = (field, value, type) => {
    if (type === "image" || type === "video") {
      setCreativeData((creativeData) => [{ ...creativeData[0], [field]: value }]);
    }
  };

  const setSingleCreative = (field, value) => {
    setCreative((prevCreative) => {
      const newCreative = { ...prevCreative, [field]: value };

      return newCreative;
    });
  };

  const handleClearContext = (key) => {
    switch (key) {
      case "creativeData":
        setCreativeData([
          {
            media_url: "",
            headline: "",
            destinationUrl: "",
            callToAction: ""
          }
        ]);
        break;
      case "profile":
        setProfile({});
        break;
      case "creative":
        setCreative({
          media_url: "",
          headline: "",
          destinationUrl: "",
          callToAction: ""
        });
        break;
      case "businessInfo":
        setBusinessInfo({});
        break;
      case "campaignData":
        setCampaignData({
          objective: "",
          start_date: "",
          ad_type: "image",
          ad_creative: [
            {
              media_url: "",
              headline: "",
              destinationUrl: "",
              callToAction: ""
            }
          ]
        });
        break;
      case "all":
        setCreativeData([
          {
            media_url: "",
            headline: "",
            destinationUrl: "",
            callToAction: ""
          }
        ]);
        setCreative({
          media_url: "",
          headline: "",
          destinationUrl: "",
          callToAction: ""
        });
        setBusinessInfo({});
        setCampaignData({
          objective: "",
          start_date: "",
          ad_type: "image"
        });
        setProfile({});
        break;
      default:
        break;
    }
  };

  const logout = () => {
    setIsLoggedIn(false);
    setAccessToken(null);
    setProfile({});
    handleClearContext("all");
    Cookies.remove("accessToken");
  };
  const setCampaignType = (val) => {
    setAdType(val);
    setCampaignInfo("ad_type", val);
  };

  const temp = (creative) => {
    setCreativeData([...creativeData, creative]);
  };

  const editedCreativeData = (creative) => {
    setCreativeData(creative);
  };

  const refreshBusinessInfo = async () => {
    await getBusinessInfo();
  };

  const getCampaignDataById = async (id) => {
    const res = await Campaigns.getCampaignById(id);
    setCampaignData(res?.data[0]);
  };

  const getCampaignById = (id) => {
    if (id) {
      getCampaignDataById(id);
    }
  };

  const removeCreative = (index) => {
    setCreativeData(creativeData?.filter((_, i) => i !== index));
  };

  return (
    <ProfileContext.Provider
      value={{
        profile,
        setProfile,
        setBusinessInfo,
        businessInfo,
        isLoggedIn,
        campaignData,
        accessToken,
        creativeData,
        creative,
        adType,
        onboardData,
        wallet,
        login,
        logout,
        setBusinessDetails,
        setCampaignInfo,
        setCreativeInfo,
        setSingleCreative,
        handleClearContext,
        getProfile,
        setCampaignType,
        temp,
        getCampaignDataById,
        getCampaignById,
        editedCreativeData,
        refreshBusinessInfo,
        removeCreative,
        setGroupCreative,
        getBusinessInfo,
        setCampaignData
      }}
    >
      {children}
    </ProfileContext.Provider>
  );
};

export default ProfileContext;
