import { parsePhoneNumber } from "libphonenumber-js";

const PhoneValidation = {
  validatePhoneNumber: (value) => {
    const indianPhoneNumberRegex = /^\+91\d{10}$/;
    if (!value?.phone) {
      return Promise.reject("Please enter your phone number");
    }
    const phoneNumber = parsePhoneNumber(value?.phone, value?.short);
    if (phoneNumber.isValid() === false) {
      return Promise.reject(new Error("Invalid phone number"));
    }
    const phone = "+" + value?.code + value?.phone;
    if (value?.code === 91 && !indianPhoneNumberRegex.test(phone)) {
      return Promise.reject("Please enter a valid Indian phone number");
    }
    return Promise.resolve();
  }
};

export default PhoneValidation;
