import apiUrl from "../config/btpApi";
import { MumfyAuth } from "./MumfyAuth";
import errorHandling from "../components/errorhandling/ErrorHandle";

const Onboarding = {
  submitOnboardData: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.onBoardingUrl + "create", "post", {}, data);
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
      return err?.response;
    }
  },

  getBusinessDetails: async () => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.onBoardingUrl + "get-all", "get");
      return response;
    } catch (err) {
      console.log("err", err);
      return errorHandling.handleErrors(err);
    }
  },

  getBusinessDetailsFromGST: async (gstNum) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.getGstDataUrl + gstNum, "get", {
        "X-RapidAPI-Key": "d71d9f5a0dmshf95cf6f23b1d5acp188144jsn3703b6e00286"
      });
      return response;
    } catch (err) {
      errorHandling.handleErrors(err?.response?.data?.message);
      return err?.response;
    }
  }
};

export default Onboarding;
