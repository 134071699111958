export const redirectToPage = async (history, profile, onboardData) => {
  console.log("onboardData", onboardData);
  if (onboardData) {
    history.push("/campaign");
  } else {
    if (Object.keys(profile).length > 0) {
      if (data?.data?.c_code?.toLowerCase() === "in") {
        history.push("/onboard");
      } else {
        history.push("/onboarding");
      }
    } else {
      message.error("Something went wrong, please try again");
    }
  }
};
