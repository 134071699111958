import { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Layout, Drawer, Affix } from "antd";
import Sidenav from "./Sidenav";
import Header from "./Header";
import Footer from "./Footer";

const { Header: AntHeader, Content, Sider } = Layout;

function Main({ children }) {
  const [visible, setVisible] = useState(false);
  const [placement, setPlacement] = useState("right");
  const [sidenavColor, setSidenavColor] = useState("#773A89");
  const [sidenavType, setSidenavType] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [siderHovered, setSiderHovered] = useState(true);

  const openDrawer = () => setVisible(!visible);
  const handleSidenavType = (type) => setSidenavType(type);
  const handleSidenavColor = (color) => setSidenavColor(color);
  const handleFixedNavbar = (type) => setFixed(type);

  let { pathname } = useLocation();

  pathname = pathname.replace(/\//g, " ");

  useEffect(() => {
    if (pathname === "rtl") {
      setPlacement("left");
    } else {
      setPlacement("right");
    }
  }, [pathname]);

  return (
    <Layout>
      <Layout
        className={`layout-dashboard ${
          pathname === "profile" ? "layout-profile" : ""
        } ${pathname === "rtl" ? "layout-dashboard-rtl" : ""}`}
      >
        <Sider
          breakpoint="lg"
          // collapsed={!siderHovered}
          width={250}
          // onMouseEnter={() => setSiderHovered(true)}
          // onMouseLeave={() => setSiderHovered(false)}
          theme="light"
          className={`sider-primary ant-layout-sider-primary ant-sider-fixed ${
            sidenavType === "#fff" ? "active-route" : ""
          }`}
          style={{
            // position: "fixed",
            // left: 0,
            // top: 0,
            // height: "100vh",
            // overflowY: "auto",
            background: sidenavType
          }}
        >
          <Sidenav color="#773A89" collapsed={siderHovered} />
        </Sider>
        <Layout className="ms-0">
          <AntHeader className={`${fixed ? "ant-header-fixed" : ""}`}>
            <Header
              onPress={openDrawer}
              name={pathname}
              subName={pathname}
              handleSidenavColor={handleSidenavColor}
              handleSidenavType={handleSidenavType}
              handleFixedNavbar={handleFixedNavbar}
            />
          </AntHeader>
          <Content className="content-ant">{children}</Content>
        </Layout>
      </Layout>
      <Footer />
    </Layout>
  );
}

export default Main;
