import apiUrl from "../config/btpApi";
import { MumfyAuth } from "./MumfyAuth";
import errorHandling from "../components/errorhandling/ErrorHandle";

const Profile = {
  requestPhoneOtp: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.requestPhoneOtp, "post", {}, data);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err?.response?.data?.message);
    }
  },

  verifyPhoneOtp: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.verifyPhoneOtp, "post", {}, data);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err?.response?.data, err?.response?.status);
    }
  },

  register: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.registerUrl, "post", {}, data);
      return response;
    } catch (err) {
      console.log("err?.response?.data?.message", err);
      errorHandling.handleErrors(err?.response?.data || err);
    }
  },

  getUserDetails: async () => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.profileUrl, "get");
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  getOtherUserDetails: async (userId) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.otherUsersProfile,
        "post",
        {},
        { id: userId }
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  getUsers: async (page, queryData) => {
    let data = {};
    const query = { ...queryData, user_status: 1 };
    data = {
      table: "mst_user",
      clause: "AND",
      query,
      sortBy: {
        column: "created_date_time",
        order: "DESC"
      }
    };

    const queryParams = {
      page: page.page,
      limit: page.limit
    };
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.searchUrl +
          "?page=" +
          (queryParams.page + 1) +
          "&limit=" +
          queryParams.limit +
          "&total=" +
          true,
        "post",
        {},
        data
      );
      return response.data;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },

  updateUser: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.updateUserUrl, "patch", {}, data);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err.response.status);
    }
  },

  updateBusinessInfo: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(apiUrl.updateBusinessInfo, "patch", {}, data);
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  }
};

export default Profile;
