import { useEffect, useContext, useState } from "react";

import { Row, Col, Dropdown, Space, Avatar } from "antd";

import { ProfileContext } from "../../context/ProfileContext";
import { useHistory } from "react-router-dom";
import propTypes from "prop-types";

function Header(props) {
  Header.propTypes = {
    props: propTypes.any
  };
  const { profile, logout, handleClearContext } = useContext(ProfileContext);
  const [items, setItems] = useState([
    {
      label: "My Profile",
      key: "1"
    },
    {
      label: "My Wallet",
      key: "2"
    },
    {
      label: "Logout",
      key: "3"
    }
  ]);
  const navigate = useHistory();

  useEffect(() => {
    if (props?.disableProfile === true) {
      setItems(items.slice(-1));
    }
  }, []);

  const onClick = (event) => {
    if (event.key === "1") {
      navigate.push("/profile");
    }
    if (event.key === "2") {
      navigate.push("/wallet");
    }
    if (event.key === "3") {
      navigate.push("/");
      handleClearContext("all");
      logout();
    }
  };

  // useEffect(() => window.scrollTo(0, 0));

  return (
    <>
      <Row gutter={[24, 0]}>
        <Col span={24} md={6}></Col>
        <Col span={24} md={18} className="header-control">
          <Dropdown
            menu={{
              items,
              onClick
            }}
          >
            <Space>
              {profile?.full_name}{" "}
              <Avatar size={30} icon={<img alt="" src={profile?.profile_pic} />} />
            </Space>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
}

export default Header;
