import apiUrl from "../config/btpApi";
import { MumfyAuth } from "./MumfyAuth";
import errorHandling from "../components/errorhandling/ErrorHandle";

const SendEmail = {
  contactUs: async (data) => {
    try {
      const response = await MumfyAuth.fetchData(
        apiUrl.sendEmailUrl,
        "post",
        {},
        { ...data, email: "arjun@mumfy.in", name: "Arjun"},
      );
      return response;
    } catch (err) {
      errorHandling.handleErrors(err, err?.response?.status);
    }
  },
};

export default SendEmail;
